import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux'
//import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {

    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path
        let modifiedPathName = (pathname === '/' || pathname.toLowerCase()==='/app' || pathname.toLowerCase()==='/app/' || pathname.toLowerCase()==='/app/b2' || pathname.toLowerCase()==='/app/b2/')? '/app/b2/home': pathname;

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                const parentLiEle = that.closest(this, 'li');
                if(menuLi[i].classList.contains('menu') && parentLiEle !== null) {
                    event.stopPropagation();

                    if(menuLi[i].classList.contains('open')) {
                        menuLi[i].classList.remove('open', 'active');
                    } else {
                        menuLi[i].classList.add('open', 'active');
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, 'li');
                        if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                            menuLi[j].classList.remove('open');
                        } else {
                            if(menuLi[j].classList.contains('open')) {
                                menuLi[j].classList.remove('open');
                            } else {
                                menuLi[j].classList.add('open');
                            }
                        }
                    }
                }
            }
        }
        const activeLi = document.querySelector('a[href="' + modifiedPathName + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    componentWillReceiveProps(nextProps) {
        const {history} = nextProps;
        const pathname = `${history.location.pathname}`;// get current path
        let modifiedPathName = (pathname === '/' || pathname.toLowerCase()==='/app' || pathname.toLowerCase()==='/app/' || pathname.toLowerCase()==='/app/b2' || pathname.toLowerCase()==='/app/b2/')? '/app/b2/home': pathname;
        const activeLi = document.querySelector('a[href="' + modifiedPathName + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    generateMenu(menu, type){
        const {history} = this.props;
        const pathname = `${history.location.pathname}`;// get current path
        let systemMenu = [];
        let module = null;
        let headers = [];
        let header = null;
        let apps = [];
        let allApps = [];

        if (menu){
            for (var i = 0; i < menu.length; i++) {
                if (menu[i].attributes.elementType.value === type){
                    if (module){
                        if (header === null){
                            if (headers.length > 0){
                                systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                                    <li className="nav-header">
                                                        {module.attributes.description.value}
                                                    </li>
                                                    {headers}
                                                    {apps}
                                                </React.Fragment>
                                );
                                headers = [];
                                header = null;
                                apps = [];
                            }
                        } else if (apps.length > 0){
                            headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="menu collapse-box">
                                             <Button>
                                                 <i className={"zmdi zmdi-"+header.attributes.image.value+" zmdi-hc-fw"}/>
                                                 <span className="nav-text">
                                                     {header.attributes.description.value}
                                                 </span>
                                             </Button>
                                             <ul className="sub-menu">
                                                 {apps}
                                             </ul>
                                         </li>
                            )
                            systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                                <li className="nav-header">
                                                    {module.attributes.description.value}
                                                </li>
                                                {headers}
                                            </React.Fragment>
                                            );
                            headers = [];
                            header = null;
                            apps = [];
                        } else if (headers.length>0){
                            systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                                <li className="nav-header">
                                                    {module.attributes.description.value}
                                                </li>
                                                {headers}
                                                {apps}
                                            </React.Fragment>
                            );
                            headers = [];
                            header = null;
                            apps = [];
                        }
                    }

                    module = menu[i];
                } else if (menu[i].attributes.elementType.value === 'HEADER'){
                    if (header && apps.length>0){
                        headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="menu collapse-box">
                                            <Button>
                                                <i className={"zmdi zmdi-"+header.attributes.image.value+" zmdi-hc-fw"}/>
                                                <span className="nav-text">
                                                    {header.attributes.description.value}
                                                </span>
                                            </Button>
                                            <ul className="sub-menu">
                                                {apps}
                                            </ul>
                                        </li>
                        )
                    }

                    header = menu[i];
                    apps = [];
                }else if (menu[i].attributes.elementType.value === 'APP'){
                    if (menu[i].attributes.subPosition.value === 0){
                        headers.push(<li key={menu[i].attributes.id.value} id={menu[i].attributes.id.value} >
                                        {(pathname.toLowerCase()===('/app/b2/'+menu[i].attributes.appName.value).toLowerCase())?
                                            <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()} onClick={(event)=>{event.preventDefault();event.stopPropagation(); return;}}>
                                                <i className={'zmdi zmdi-' + menu[i].attributes.image.value + ' zmdi-hc-fw'}/>
                                                <span className="nav-text">{menu[i].attributes.description.value}</span>
                                            </NavLink>:
                                            <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()}>
                                                <i className={'zmdi zmdi-' + menu[i].attributes.image.value + ' zmdi-hc-fw'}/>
                                                <span className="nav-text">{menu[i].attributes.description.value}</span>
                                            </NavLink>
                                      }
                                  </li>
                        );
                    }else{
                        apps.push(<li key={menu[i].attributes.id.value} id={menu[i].attributes.id.value} >
                                        {(pathname.toLowerCase()===('/app/b2/'+menu[i].attributes.appName.value).toLowerCase())?
                                            <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()} onClick={(event)=>{event.preventDefault();event.stopPropagation(); return;}}>
                                                <span className="nav-text">{menu[i].attributes.description.value}</span>
                                            </NavLink>:
                                            <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()}>
                                                <span className="nav-text">{menu[i].attributes.description.value}</span>
                                            </NavLink>
                                      }
                                  </li>
                        );
                    }

                    allApps.push(menu[i]);
                }
            }

            if (header === null){
                // if apps is not empty, Menu will look distorted, but it has to show apps as they are accessible and so that developer can fix menu configuration
                if (module !== null){
                    if (headers.length>0 || apps.length >0 ){
                        systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                            <li className="nav-header">
                                                {module.attributes.description.value}
                                            </li>
                                            {headers}
                                            {apps}
                                        </React.Fragment>
                                    );
                    }
                }else{
                    if (headers.length>0 || apps.length >0 ){
                        systemMenu.push(<React.Fragment key="UNNAMED MODULE">
                                            <li className="nav-header">
                                                UNNAMED MODULE
                                            </li>
                                            {headers}
                                            {apps}
                                        </React.Fragment>
                                    );
                    }
                }
            } else if (apps.length>0){
                headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="menu collapse-box">
                                 <Button>
                                     <i className={"zmdi zmdi-"+header.attributes.image.value+" zmdi-hc-fw"}/>
                                     <span className="nav-text">
                                         {header.attributes.description.value}
                                     </span>
                                 </Button>
                                 <ul className="sub-menu">
                                     {apps}
                                 </ul>
                             </li>
                )

                if (module !== null){
                    systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                        <li className="nav-header">
                                            {module.attributes.description.value}
                                        </li>
                                        {headers}
                                    </React.Fragment>
                                );
                }else{
                     systemMenu.push(<React.Fragment key="UNNAMED MODULE">
                                         <li className="nav-header">
                                             UNNAMED MODULE
                                         </li>
                                         {headers}
                                     </React.Fragment>
                                 );
                }
            } else if (apps.length === 0){
                if (headers.length>0 ){
                    if (module !== null){
                        systemMenu.push(<React.Fragment key={module.attributes.id.value}>
                                                <li className="nav-header">
                                                    {module.attributes.description.value}
                                                </li>
                                                {headers}
                                            </React.Fragment>
                                        );
                    } else{
                         systemMenu.push(<React.Fragment key="UNNAMED MODULE">
                                             <li className="nav-header">
                                                 UNNAMED MODULE
                                             </li>
                                             {headers}
                                         </React.Fragment>
                                     );
                    }
                }
            }

            headers = [];
            header = null;
            apps = [];
        }

        if (type==='MODULE'){
//            if (this.props.defaultApp && allApps.length===1){
//                if (this.props.defaultApp.toLowerCase() === allApps[0].attributes.appName.value.toLowerCase()){
//                    return <React.Fragment></React.Fragment>;
//                }
//            }
        }else if (type==='DASHBOARD'){
//            if (allApps.length===1){
//                return <React.Fragment></React.Fragment>;
//            }
        }

        return systemMenu;
    }

    render() {
        return (
            <CustomScrollbars className=" scrollbar">
                <ul className="nav-menu">
                    {this.generateMenu(this.props.dashboards, 'DASHBOARD')}
                    {this.generateMenu(this.props.systemMenu, 'MODULE')}
                </ul>
            </CustomScrollbars>
    );
  }
}
const mapStateToProps = ({b2State}) => {
    const {
        dashboards,
        systemMenu,
        defaultApp
    } = b2State;

    return {
        dashboards,
        systemMenu,
        defaultApp
    }
};

export default withRouter(connect(mapStateToProps)(SidenavContent));