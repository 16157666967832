import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {connect} from 'react-redux'

class Menu extends Component {
    componentDidMount() {
        const {history} = this.props;

        const pathname = `#${history.location.pathname}`;// get current path
        const mainMenu = document.getElementsByClassName('nav-item');
        for (let i = 0; i < mainMenu.length; i++) {
            mainMenu[i].onclick = function () {
                for (let j = 0; j < mainMenu.length; j++) {
                    if (mainMenu[j].classList.contains('active')) {
                        mainMenu[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }

        const subMenuLi = document.getElementsByClassName('nav-arrow');

        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function () {
                for (let j = 0; j < subMenuLi.length; j++) {
                    if (subMenuLi[j].classList.contains('active')) {
                        subMenuLi[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element

        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('active');
            } else {
                this.closest(activeLi, 'li').classList.add('active');
            }
            const parentNav = this.closest(activeNav, '.nav-item');
            if (parentNav) {
                parentNav.classList.add('active');
            }
        } catch (e) {
        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {
        }

        return null;
    }

    generateMenu(menu, type){
        const {history} = this.props;
        const pathname = `${history.location.pathname}`;// get current path
        let systemMenu = [];
        let module = null;
        let headers = [];
        let header = null;
        let apps = [];
        let allApps = [];

//         Template to display a link in menu (to be used when there is one app only so menu is not needed
//        systemMenu.push(
//            <NavLink to={('/app/b2/driverewd').toLowerCase()}>
//                <li className="nav-item">
//                     <span className="nav-link"><i className={'zmdi zmdi-view-dashboard zmdi-hc-fw'}/> XXXXXX</span>
//                </li>
//            </NavLink>
//        );

        if (menu){
            for (var i = 0; i < menu.length; i++) {
                if (menu[i].attributes.elementType.value === type){
                    if (module){
                        if (header === null){
                            if (headers.length>0){
                                systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                                     <span className="nav-link">{module.attributes.description.value}</span>
                                                     <ul className="sub-menu">
                                                        {headers}
                                                        {apps}
                                                    </ul>
                                                 </li>);
                                headers = [];
                                header = null;
                                apps = [];
                            }
                        } else if (apps.length > 0){
                            headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="nav-arrow">
                                            <span className="nav-link">
                                                <i className={'zmdi zmdi-' + header.attributes.image.value + ' zmdi-hc-fw'}/>
                                                <span className="nav-text">
                                                    {header.attributes.description.value}
                                                </span>
                                            </span>
                                            <ul className="sub-menu">
                                                {apps}
                                            </ul>
                                        </li>
                            )
                            systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                                 <span className="nav-link">{module.attributes.description.value}</span>
                                                 <ul className="sub-menu">
                                                    {headers}
                                                </ul>
                                             </li>);
                            headers = [];
                            header = null;
                            apps = [];
                        } else if (headers.length>0){
                            systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                                 <span className="nav-link">{module.attributes.description.value}</span>
                                                 <ul className="sub-menu">
                                                    {headers}
                                                    {apps}
                                                </ul>
                                             </li>);
                            headers = [];
                            header = null;
                            apps = [];
                        }
                    }

                    module = menu[i];
                } else if (menu[i].attributes.elementType.value === 'HEADER'){
                    if (header && apps.length>0){
                        headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="nav-arrow">
                                        <span className="nav-link">
                                            <i className={'zmdi zmdi-' + header.attributes.image.value + ' zmdi-hc-fw'}/>
                                            <span className="nav-text">
                                                {header.attributes.description.value}
                                            </span>
                                        </span>
                                        <ul className="sub-menu">
                                            {apps}
                                        </ul>
                                    </li>
                        )
                    }

                    header = menu[i];
                    apps = [];
                }else if (menu[i].attributes.elementType.value === 'APP'){
                    if (menu[i].attributes.subPosition.value === 0){
                        headers.push(<li key={menu[i].attributes.id.value} id={menu[i].attributes.id.value}>
                                        <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()}>
                                            <i className={'zmdi zmdi-' + menu[i].attributes.image.value + ' zmdi-hc-fw'}/>
                                            <span className="nav-text">{menu[i].attributes.description.value}</span>
                                        </NavLink>
                                    </li>
                        );
                    }else{
                        apps.push(<li key={menu[i].attributes.id.value} id={menu[i].attributes.id.value}>
                                    <NavLink className="prepend-icon" to={('/app/b2/'+menu[i].attributes.appName.value).toLowerCase()}>
                                        <i className={'zmdi zmdi-' + menu[i].attributes.image.value + ' zmdi-hc-fw'}/>
                                        <span className="nav-text">{menu[i].attributes.description.value}</span>
                                    </NavLink>
                                </li>
                        );
                    }

                    allApps.push(menu[i]);
                }
            }

            if (header === null){
                // if apps is not empty, Menu will look distorted, but it has to show apps as they are accessible and so that developer can fix menu configuration
                if (module !== null){
                    if (headers.length>0 || apps.length >0 ){
                        systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                         <span className="nav-link">{module.attributes.description.value}</span>
                                         <ul className="sub-menu">
                                            {headers}
                                            {apps}
                                        </ul>
                                     </li>);
                    }
                }else{
                    systemMenu.push(<li key="UNNAMED MODULE" id="UNNAMED MODULE" className="nav-item">
                                         <span className="nav-link">UNNAMED MODULE</span>
                                         <ul className="sub-menu">
                                            {headers}
                                            {apps}
                                        </ul>
                                     </li>);
                }
            } else if (apps.length>0){
                headers.push(<li key={header.attributes.id.value} id={header.attributes.id.value} className="nav-arrow">
                                <span className="nav-link">
                                    <i className={'zmdi zmdi-' + header.attributes.image.value + ' zmdi-hc-fw'}/>
                                    <span className="nav-text">
                                        {header.attributes.description.value}
                                    </span>
                                </span>
                                <ul className="sub-menu">
                                    {apps}
                                </ul>
                            </li>
                )

                if (module !== null){
                    systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                         <span className="nav-link">{module.attributes.description.value}</span>
                                         <ul className="sub-menu">
                                            {headers}
                                        </ul>
                                     </li>);
                }else{
                    systemMenu.push(<li key="UNNAMED MODULE" id="UNNAMED MODULE" className="nav-item">
                                         <span className="nav-link">UNNAMED MODULE</span>
                                         <ul className="sub-menu">
                                            {headers}
                                        </ul>
                                     </li>);
                }
            } else if (apps.length === 0){
                if (headers.length>0){
                    if (module !== null){
                        systemMenu.push(<li key={module.attributes.id.value} id={module.attributes.id.value} className="nav-item">
                                             <span className="nav-link">{module.attributes.description.value}</span>
                                             <ul className="sub-menu">
                                                {headers}
                                            </ul>
                                         </li>);
                    }else{
                        systemMenu.push(<li key="UNNAMED MODULE" id="UNNAMED MODULE" className="nav-item">
                                             <span className="nav-link">UNNAMED MODULE</span>
                                             <ul className="sub-menu">
                                                {headers}
                                            </ul>
                                         </li>);
                    }
                }
            }

            headers = [];
            header = null;
            apps = [];
        }

        if (this.props.defaultApp && allApps.length===1){
            if (this.props.defaultApp.toLowerCase() === allApps[0].attributes.appName.value.toLowerCase()){
                return <React.Fragment></React.Fragment>;
            }
        }

        if (type==='MODULE'){
//            if (this.props.defaultApp && allApps.length===1){
//                if (this.props.defaultApp.toLowerCase() === allApps[0].attributes.appName.value.toLowerCase()){
//                    return <React.Fragment></React.Fragment>;
//                }
//            }
        }else if (type==='DASHBOARD'){
//            if (allApps.length===1){
//                return <React.Fragment></React.Fragment>;
//            }
        }

        return systemMenu;
    }

    render() {
        return (<div className="app-main-menu d-none d-md-block">
                    <ul className="navbar-nav navbar-nav-mega">
                        {this.generateMenu(this.props.dashboards, 'DASHBOARD')}
                        {this.generateMenu(this.props.systemMenu, 'MODULE')}
                    </ul>
                  </div>);
    }
}

const mapStateToProps = ({b2State}) => {
    const {
        dashboards,
        systemMenu,
        defaultApp
    } = b2State;

    return {
        dashboards,
        systemMenu,
        defaultApp
    }
};

export default withRouter(connect(mapStateToProps)(Menu));